import axios from "axios"
import i18n from "@/plugins/i18n"

const http = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}/api`,
  timeout: 100000,
})
http.defaults.params = {}

// http.interceptors.request.use(
//   function (config) {
//     let lang = i18n.locale
//     if (lang === "kz") {
//       lang = "kk"
//     }
//     config.params["locale"] = lang
//     return config
//   },
//   function (error) {
//     return Promise.reject(error)
//   },
// )

http.interceptors.response.use(
  function (response) {
    let lang = i18n.locale
    if (lang === "kz") {
      lang = "kk"
    }

    if (lang === "kk") {
      console.log("response lol", response.data?.data)
      response.data?.data.forEach((e) => {
        const localeAttributes = e.localizations[0]
        if (localeAttributes) {
          for (const [key, val] of Object.entries(localeAttributes)) {
            if (key !== "id") {
              e[key] = val
            }
          }
        }
      })
      // const localeId = response?.data?.data?.attributes?.localizations?.data[0]?.attributes?.id
      // if (localeId) {
      //   response.data.data.attributes.id = localeId
      // }

      return response
    }
    return response
  },
  function (error) {
    return Promise.reject(error)
  },
)
export default http
