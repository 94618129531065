import axios from "@/plugins/axios"
import axiosForCaregory from "@/plugins/axiosForCaregory"
import axiosForSubcaregory from "@/plugins/axiosForSubcaregory"
import qs from "qs"

export const getAllCategories = async () => {
  const query = qs.stringify(
    {
      populate: {
        subcategories: {
          populate: "*",
          categories: {
            populate: "*",
          },
          products: {
            localizations: {
              populate: "*",
            },
          },
        },
        localizations: {
          populate: "*",
        },
      },
      sort: ["order:asc"],
    },
    {
      encodeValuesOnly: true,
    },
  )
  const response = await axios.get(`/categories?${query}`)
  return response?.data?.data
}

export const getAllCategoriesFiltered = async () => {
  const query = qs.stringify({
    populate: {
      subcategories: {
        populate: "*",
        categories: {
          populate: "*",
        },
        products: {
          localizations: {
            populate: "*",
          },
        },
      },
      localizations: {
        populate: "*",
      },
    },
    sort: ["order:asc"],
  })
  const response = await axiosForCaregory.get(`/categories-filtered?${query}`)
  return response?.data?.data
}

export const getCategoryByIds = async (ids) => {
  const query = qs.stringify({
    populate: {
      subcategories: {
        populate: "*",
        categories: {
          populate: "*",
        },
      },
      localizations: {
        populate: "*",
        products: {
          populate: "*",
        },
      },
      products: {
        populate: "*",
      },
    },
  })
  const response = await axios.get(`/categories/${ids}?${query}`)
  return response?.data?.data
}

export const getAllSubcategories = async () => {
  const query = qs.stringify({
    populate: {
      categories: {
        populate: "*",
      },
      products: {
        populate: "*",
      },
    },
  })
  const response = await axios.get(`/subcategories?${query}`)
  return response?.data?.data
}

export const getAllSubcategoryId = async (id) => {
  const query = qs.stringify(
    {
      populate: {
        categories: {
          populate: "*",
        },
        products: {
          populate: "*",
          image: "*",
          localizations: "*",
        },
        localizations: {
          populate: "*",
        },
      },
      filters: {
        id: {
          $in: id,
        },
      },
    },
    {
      encodeValuesOnly: true,
    },
  )
  const response = await axiosForSubcaregory.get(`/subcategories?${query}`)
  return response?.data?.data
}
