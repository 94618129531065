<template>
  <header class="header">
    <div class="header__top-wrapper">
      <div class="container">
        <div class="header__top">
          <div
            class="header__burger"
            @click="showMenu"
          >
            <img
              src="@/assets/img/icons/header-burger.svg"
              alt=""
            />
          </div>
          <div
            @click="closeSearch"
            class="header__logo"
          >
            <locale-router-link to="">
              <img
                src="@/assets/img/icons/logo.svg"
                alt=""
              />
            </locale-router-link>
          </div>
          <div
            v-if="!isSearchingOpen"
            @click="mobileSearch(true)"
            class="search__btn"
          >
            <svg
              width="19"
              height="19"
              viewBox="0 0 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.5792 11.9497H12.721L12.4168 11.6564C13.4814 10.418 14.1224 8.81018 14.1224 7.06118C14.1224 3.16124 10.9611 0 7.06118 0C3.16124 0 0 3.16124 0 7.06118C0 10.9611 3.16124 14.1224 7.06118 14.1224C8.81018 14.1224 10.418 13.4814 11.6564 12.4168L11.9497 12.721V13.5792L17.3814 19L19 17.3814L13.5792 11.9497ZM7.06118 11.9497C4.3562 11.9497 2.17267 9.76615 2.17267 7.06118C2.17267 4.3562 4.3562 2.17267 7.06118 2.17267C9.76615 2.17267 11.9497 4.3562 11.9497 7.06118C11.9497 9.76615 9.76615 11.9497 7.06118 11.9497Z"
                fill="#fff"
              />
            </svg>
          </div>
          <div
            v-else
            @click="mobileSearch(false)"
            class="search__btn"
          >
            <img
              src="@/assets/img/header-menu/crossmobile.svg"
              alt=""
            />
          </div>
          <mobile-search />
          <div
            class="login__btn"
            v-if="!$cookies.get('profile')"
            @click="openIsAuthActive"
          >
            {{ $t("header.login") }}
          </div>
          <button
            v-else
            class="header__login header__settings-item logged__user"
            @click="openMenu"
          >
            <img
              src="@/assets/img/icons/login-icon.svg"
              alt=""
            />
            {{ $cookies.get("profile").first_name }}
          </button>
          <div class="header__main">
            <div class="header__main-top">
              <form
                class="header__search"
                @submit.prevent="searchGo"
              >
                <input
                  class="header__search-input"
                  type="text"
                  :placeholder="$t('find')"
                  v-model="searchItem"
                />
                <button class="header__search-btn">
                  <img
                    class="header__search-img"
                    src="@/assets/img/icons/search-icon.svg"
                    alt=""
                  />
                </button>
              </form>
              <div class="header__settings">
                <div
                  v-if="selectedCity"
                  class="header__address header__settings-item"
                  @click="openSelectCity"
                >
                  {{ selectedCity.attributes.name.substring(0, 3) }}
                </div>
                <lang-change class="header__settings-item" />
                <div
                  style="text-transform: none"
                  v-if="!$cookies.get('profile')"
                  class="header__login header__settings-item"
                  @click="openIsAuthActive"
                >
                  <img
                    src="@/assets/img/icons/login-icon.svg"
                    alt=""
                  />
                  {{ $t("header.login") }}
                </div>
                <button
                  v-else
                  class="header__login header__settings-item"
                  @click="openMenu"
                >
                  <img
                    src="@/assets/img/icons/login-icon.svg"
                    alt=""
                  />
                  {{ $cookies.get("profile").first_name }}
                </button>
              </div>
            </div>
            <nav class="header__main-nav">
              <ul class="header__main-nav--list">
                <li class="header__main-nav--item">
                  <locale-router-link
                    to="catalog"
                    class="header__main-nav--link header__main-nav--blue"
                    >{{ $t("header.discounts") }}</locale-router-link
                  >
                </li>
                <li class="header__main-nav--item">
                  <locale-router-link
                    to="stocks"
                    class="header__main-nav--link header__main-nav--red"
                    >{{ $t("header.stocks") }}</locale-router-link
                  >
                </li>
                <li class="header__main-nav--item">
                  <a
                    class="header__main-nav--link"
                    v-if="!$cookies.get('profile')"
                    @click="getIsOpenHiddenModal(true)"
                  >
                    {{ $t("header.heatingSystems") }}
                  </a>
                  <locale-router-link
                    v-else
                    to="heating-systems/bat"
                    class="header__main-nav--link"
                    >{{ $t("header.heatingSystems") }}</locale-router-link
                  >
                </li>
                <li class="header__main-nav--item">
                  <locale-router-link
                    to="recipes"
                    class="header__main-nav--link"
                    >{{ $t("header.coctails") }}</locale-router-link
                  >
                </li>
                <li class="header__main-nav--item">
                  <locale-router-link
                    to="brands"
                    class="header__main-nav--link"
                    >{{ $t("header.brands") }}</locale-router-link
                  >
                </li>
                <li class="header__main-nav--item">
                  <button
                    @click="openMenu"
                    class="header__main-nav--menu"
                  >
                    <img src="@/assets/img/header-menu/burger.svg" />{{ $t("header.menu") }}
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
    <div
      class="container"
      style="position: relative"
      @mouseleave="activeNavBar = 0"
      v-if="products.length"
    >
      <nav class="header__nav">
        <ul
          class="header__nav-list"
          :class="{
            'items-4': categories.length <= 4,
          }"
        >
          <li
            class="header__nav-item"
            v-for="(category, index) in categories"
            :key="index"
          >
            <div class="header__nav-link">
              <locale-router-link
                @mouseenter.native="getCategoryId(category.id)"
                tag="p"
                :to="`catalog?category=${category.id}`"
              >
                {{ category.label }}
              </locale-router-link>
              <div class="header__nav-hover">
                <div class="header__nav-hover--content">
                  <div
                    v-for="(item, index) in menu.data"
                    :key="index"
                    class="header__nav-hover--item"
                    :style="{ display: item.data.length ? 'block' : 'none' }"
                  >
                    <h1>{{ item.label }}</h1>

                    <ul>
                      <locale-router-link
                        class="header__nav-hover--subitem"
                        tag="li"
                        :to="`catalog?category=${category.id}&${getQuery(subitem)}`"
                        v-for="(subitem, index) in item.data"
                        :key="index"
                      >
                        {{ subitem.label ? subitem.label : subitem }}
                        <span v-if="item.type === 'volume'">Л</span>
                        <span v-if="item.type === 'strenght'">%</span>
                      </locale-router-link>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </nav>
    </div>
  </header>
  <!-- <app-modal-city v-if="isShowModalCity" @close="isShowModalCity = false" /> -->
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex"
import { actionTypes, mutationTypes } from "@/store"
import { actionTypes as actionTypesCategories } from "@/store/modules/categories"
import { actionTypes as actionTypesCatalog } from "@/store/modules/catalog"
import { getProductsByIds } from "@/api/products"
import { getAllCategoriesFiltered, getAllSubcategoryId } from "@/api/category"
export default {
  name: "AppHeader",

  components: {
    MobileSearch: () => import("@/components/common/MobileSearch.vue"),
    LangChange: () => import("@/components/language/LangChange.vue"),
    //   AppModalCity: () => import("@/components/modal/AppModalCity.vue"),
  },

  props: {
    fixed: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      selected: false,
      activeMenu: false,
      searchTitle: "",
      termSearch: "",
      searchEntity: null,
      headerSearch: false,
      searchItem: "",

      // menu переписать
      menuAttributes: [
        {
          type: "countries",
          value: "country",
          data: [],
        },
        {
          type: "sugar",
          value: "sugar",
          data: [],
        },
        {
          type: "volume",
          value: "volume",
          data: [],
        },
        {
          type: "strenght",
          value: "strenght",
          data: [],
        },
        {
          type: "vinePlace",
          value: "vine_place",
          data: [],
        },
      ],
      categories: [],
      menu: {
        category: null,
        data: [
          {
            type: "countries",
            label: this.$t("header.categoryTypes.countries"),
            data: [],
          },
          {
            type: "view",
            label: this.$t("header.categoryTypes.view"),
            data: [],
          },
          {
            type: "sugar",
            label: this.$t("header.categoryTypes.sugar"),
            data: [],
          },
          {
            type: "volume",
            label: this.$t("header.categoryTypes.volume"),
            data: [],
          },
          {
            type: "strenght",
            label: this.$t("header.categoryTypes.strenght"),
            data: [],
          },
          {
            type: "vinePlace",
            label: this.$t("header.categoryTypes.vinePlace"),
            data: [],
          },
        ],
      },
      productsIds: [],
      products: [],
      subcategoriesIds: [],
      subcategories: [],
    }
  },

  computed: {
    ...mapState(["isSearchingOpen"]),
    ...mapState(["isOpenMenu"]),
    ...mapState("cityModule", ["selectedCity"]),
    ...mapState("catalogModule", { selectedFilters: "filters" }),
    isShowHeader() {
      if (this.$router.app._route.fullPath === `/${this.$i18n.locale}/search` && this.$isMobile()) {
        return false
      }
      return true
    },
    isShowSearchTitle() {
      if (this.$router.app._route.fullPath === `/${this.$i18n.locale}/search`) {
        return true && this.$isMobile()
      }
      return false && this.$isMobile()
    },
  },

  created() {
    // getAllCategories().then((res) => {
    //   this.categories = res
    //   this.getCategories(res)
    // })
    getAllCategoriesFiltered().then((res) => {
      this.categories = res
      this.getCategories(res)

      // get productsIds and subcategoriesIds
      this.categories.forEach((category) => {
        this.productsIds.push({ id: category.id, data: [] })
        this.subcategoriesIds.push({ id: category.id, data: [] })
        category.subcategories.forEach((subcategory) => {
          if (subcategory.products.length) {
            this.subcategoriesIds.find((e) => e.id === category.id).data.push(subcategory.id)
            subcategory.products.forEach((product) => {
              this.productsIds.find((e) => e.id === category.id).data.push(product.id)
            })
            this.productsIds.find((e) => e.id === category.id).data.slice(0, 70)
          }
        })
      })
      // get products and subcategories
      this.subcategoriesIds.forEach((subcategoryId) => {
        this.subcategories.push({ id: subcategoryId.id, data: [] })
        getAllSubcategoryId(subcategoryId.data).then((res) => {
          this.subcategories.find((e) => e.id === subcategoryId.id).data = res
        })
      })
      this.productsIds.forEach((productId) => {
        this.products.push({ id: productId.id, data: [] })
        getProductsByIds(productId.data.slice(0, 70)).then((res) => {
          console.log("res", res)
          this.products.find((e) => e.id === productId.id).data = res
        })
      })

      // set in vuex products and subcategories
      this.getCategoriesProducts(this.products)
      this.getSubcategories(this.subcategories)
    })
  },

  mounted() {
    if (this.$router === `/${this.$i18n.locale}/search`) {
      this.headerSearch = true
    }
  },

  methods: {
    ...mapActions({
      openMenu: actionTypes.changeIsOpenMenu,
      openSelectCity: actionTypes.changeIsOpenSelectCity,
      openIsAuthActive: actionTypes.changeIsAuthActive,
      getIsOpenHiddenModal: actionTypes.getIsOpenHiddenModal,
    }),
    ...mapActions("categoriesModule", {
      getCategories: actionTypesCategories.getCategories,
      getCategoriesProducts: actionTypesCategories.getCategoriesProducts,
      getSubcategories: actionTypesCategories.getSubcategories,
    }),
    ...mapActions("catalogModule", {
      getMenu: actionTypesCatalog.getMenu,
    }),
    ...mapMutations({
      modalSearching: mutationTypes.openSearchingModal,
    }),
    mobileSearch(value) {
      this.modalSearching(value)
    },
    closeSearch() {
      this.modalSearching(false)
    },
    showMenu() {
      document?.querySelector("body").classList.add("no-scroll")
      this.openMenu()
    },
    searchGo() {
      if (this.searchItem.length > 0) {
        this.$router.push({ path: "/catalog", query: { search: this.searchItem } })
      } else {
        this.$router.push({ path: "/catalog" })
      }
    },
    getCategoryId(id) {
      this.resetMenu()

      // to get all types from products
      let category
      category = this.categories.find((e) => e.id === id)
      let products
      products = this.products.find((e) => e.id === id)
      console.log("products", products)
      products.data.forEach((product) => {
        this.menuAttributes.forEach((atr) => {
          atr.data.push(product.attributes[atr.value])
        })
      })
      console.log(this.menuAttributes)
      // menu
      this.menu.category = category
      this.menuAttributes.forEach((atr) => {
        this.menu.data.find((e) => e.type === atr.type).data = Array.from(
          new Set(atr.data.filter((element) => element != null && element != "")),
        ).sort()
      })

      //additional settings for view type
      this.subcategories
        .find((e) => e.id == category.id)
        .data.forEach((e) => {
          if (e?.attributes?.products?.data?.length) {
            this.menu.data
              .find((e) => e.type === "view")
              .data.push({
                id: e.id,
                label: e.attributes.label,
              })
          }
        })
    },
    resetMenu() {
      this.menu.data.forEach((e) => {
        e.data = Array()
      })
      this.menuAttributes.forEach((data) => {
        data.data = []
      })
    },
    getQuery(item) {
      if (item?.id) {
        return `&view=${item.id}`
      } else {
        return `&label=${item}`
      }
    },
  },
}
</script>

<style
  lang="scss"
  scoped
>
.is-fixed {
  width: 100%;
  background: #fff;
  transition: 0.3s;
}

.header__nav-link {
  &:hover {
    .header__nav-hover {
      display: block;
    }
  }
}

.header__nav-hover {
  position: absolute;
  display: none;
  left: 0;
  z-index: 9999;
  padding: 0 30px;
  padding-bottom: 30px;
  width: 100%;
  height: auto;
  max-height: 100%;
  &--content {
    display: flex;
    justify-content: flex-start;
    padding: 47px 40px 40px;
    background: #303132;
  }
  &--item {
    max-width: 125px;
    & + & {
      margin-left: 110px;
    }
    @media (max-width: 900px) {
      & + & {
        margin-left: 55px;
      }
    }
    h1 {
      margin: 0 0 10px;
      font-size: 18px;
      line-height: 85.98%;
      font-family: "CeraPro Bold";
      color: #ffffff;
    }
  }
  &--subitem {
    cursor: pointer;
    font-size: 16px;
    margin-top: 16px;
    line-height: 100.98%;
    white-space: normal;
    color: #ffffff;
    font-family: "CeraPro Regular";
  }
}
.header__nav-hover--subitem {
  white-space: normal;
}

@media (max-width: 900px) {
  .header__nav-hover--subitem {
    font-size: 14px;
    margin-top: 8px;
  }
  .header__nav-hover--item {
    h1 {
      font-size: 15px;
    }
  }
  .header__nav-hover--content {
    overflow-x: auto;
  }
}
</style>
